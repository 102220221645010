// toolbar工具栏的工具选项（默认展示全部）
const toolOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote', 'code-block'],
  [{ header: 1 }, { header: 2 }],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }],
  [{ indent: '-1' }, { indent: '+1' }],
  [{ direction: 'rtl' }],
  [
    {
      size: [
        '10px',
        '12px',
        '14px',
        '16px',
        '18px',
        '20px',
        '22px',
        '24px',
        '26px',
        '32px',
        '48px'
      ]
    }
  ],
  [{ color: [] }, { background: [] }],
  [{ font: [] }],
  [{ align: [] }],
  ['clean'],
  ['image']
];

export default {
  toolOptions
};
